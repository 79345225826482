/* You can add global styles to this file, and also import other style files */
@import 'primeng/resources/primeng.css';
@import '@pv-frontend/pv-shared-components/resources/styles/theme.css';
@import '@pv-frontend/pv-shared-components/resources/styles/primeflex.css';
@import '@pv-frontend/pv-shared-components/resources/styles/custom-theme.scss';
@import './assets/variables.css';
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

html,
body {
  margin: 0;
  font-size: 16px;
  line-height: 1.5;
  overflow-x: hidden !important;
  &,
  * {
    font-family: 'Poppins' !important;
  }
}

.p-button:enabled:hover,
.p-button:enabled:focus {
  background: var(--surface-0) !important;
  box-shadow: none !important;
  color: var(--primary-color) !important;
}

.p-button:disabled {
  box-shadow: none !important;
}
.p-button.p-button-raised {
  box-shadow: none !important;
}

.p-toast .p-toast-message.p-toast-message-error {
  background: var(
    --feedback-background-negative-high-contrast-focus
  ) !important;
  color: var(--error-red);
}

.p-toast .p-toast-message.p-toast-message-success {
  background: var(
    --feedback-background-positive-high-contrast-focus
  ) !important;
  color: var(--green-600);
}
